import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import headerImage from "../../../assets/images/lead-magnet-loader-header.png";
import fundingCalculatorTemplate from "../../../assets/images/funding-calculator-template.png";
import { fundingPerks } from "../../../constants/constants";
import { getFundingValue } from "../../../utils/common";
import { ReactComponent as StarIcon } from "../../../assets/icons/star_icon.svg";
import { ReactComponent as ContactPVXIcon } from "../../../assets/icons/contact_pvx_icon.svg";
import { ReactComponent as SubmitForUnderwritingIcon } from "../../../assets/icons/submit_for_underwriting_icon.svg";
import { ReactComponent as ReceiveTermSheetIcon } from "../../../assets/icons/receive_term_sheet_icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow_icon.svg";
import { NAV_ROUTES } from "../../../constants/navRoutes";
import { labels, messages } from "@utils/translations/en";

interface FundingCalculatorEmailConfirmationPageP {
  id: string;
  monthlyMarketingSpend: number;
}

const FundingCalculatorEmailConfirmation: React.FC<FundingCalculatorEmailConfirmationPageP> = ({
  id,
  monthlyMarketingSpend,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const availableFunding = getFundingValue(monthlyMarketingSpend);

  const whatsNextFinancingSteps = [
    {
      icon: <ContactPVXIcon style={{ width: isMobile ? "4rem" : "auto", flexShrink: 0 }} />,
      text: "Schedule a call with us here",
    },
    {
      icon: <SubmitForUnderwritingIcon style={{ width: isMobile ? "4rem" : "auto", flexShrink: 0 }} />,
      text: "Prepare a standardized data table to submit for underwriting",
    },
    {
      icon: <ReceiveTermSheetIcon style={{ width: isMobile ? "4rem" : "auto", flexShrink: 0 }} />,
      text: "Receive a formal funding term sheet within days",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", overflowY: "auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          gap: isMobile ? "16px" : "32px",
          background: "linear-gradient(to right, #B3E5FC, #FFB6C1)",
          width: "100%",
          p: "3rem",
        }}
      >
        <Box sx={{ width: isMobile ? "100%" : "33rem", px: isMobile ? "2rem" : 0 }}>
          <Typography
            fontSize={isMobile ? "1rem" : "2.125rem"}
            fontWeight="800"
            color="#11111F"
            lineHeight={isMobile ? "1.25rem" : "40px"}
          >
            {messages.fundingCalculatorLanding.checkInboxInFewMinutes}
          </Typography>
        </Box>
        <img src={headerImage} alt="loader-header-image" style={{ width: isMobile ? "12rem" : "20rem" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingY: "2rem",
          width: "100%",
          px: isMobile ? "1rem" : 0
        }}
      >
        <Box>
          <Typography
            fontSize={isMobile ? "1.5rem" : "2.125rem"}
            fontWeight="700"
            lineHeight="40px"
            color="#424242"
            marginBottom="2rem"
          >
            {messages.fundingCalculatorLanding.getFundingUpTo}
            <span style={{ color: "#59C1E6" }}>{availableFunding}</span>
          </Typography>
          <Box
            sx={{
              display: isMobile ? "flex" : "grid",
              flexDirection: isMobile ? "column" : "row",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              gridRowGap: "26px",
              gridColumnGap: "32px",
            }}
          >
            {fundingPerks.map((perk: any, index: number) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: isMobile ? "8px" : "16px" }}
              >
                <StarIcon style={{ margin: isMobile ? 0 : "10px", flexShrink: 0 }} />
                <Typography fontWeight="500" color="#11111F" maxWidth={isMobile ? "100%" : "21.5rem"}>
                  {perk}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Typography color="#9E9E9E" fontSize="14px" marginTop="2rem" px={isMobile ? "0.8rem" : 0}>
          <b>{messages.fundingCalculatorLanding.disclaimer}</b><br />{messages.fundingCalculatorLanding.disclaimerMessage}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingY: "2rem",
          background: theme.palette.custom_field_background,
        }}
      >
        <Box width={isMobile ? "100%" : "55rem"} px={isMobile ? "0.8rem" : 0}>
          <Typography
            fontSize={isMobile ? "1.5rem" : "2.125rem"}
            color="#424242"
            fontWeight="700"
            marginBottom="2rem"
          >
            {messages.fundingCalculatorLanding.whatsNext}
          </Typography>
          <Box>
            {whatsNextFinancingSteps.map((step, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  paddingX: isMobile ? 0 : "1.5rem",
                }}
              >
                <Box>
                  {step.icon}
                </Box>
                {index === 0 ? (
                  <Typography
                    component="a"
                    href="https://pvxpartners.pipedrive.com/scheduler/KkdPWGCK/jeff-cohen-pvx-partners"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="#424242"
                    fontWeight="600"
                    fontSize={isMobile ? "1rem" : "1.25rem"}
                    sx={{ cursor: "pointer", textDecoration: 'none' }}
                  >
                    {step.text}
                  </Typography>
                ) : (
                  <Typography
                    color="#424242"
                    fontWeight="600"
                    fontSize={isMobile ? "1rem" : "1.25rem"}
                  >
                    {step.text}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingY: "2rem",
        }}
      >
        <Box sx={{ width: isMobile ? "100%" : "56rem", display: "flex", flexDirection: "column", gap: "1.5rem", px: isMobile ? "1rem" : 0 }}>
          <Typography
            color="#424242"
            fontSize={isMobile ? "1.5rem" : "2.125rem"}
            fontWeight="700"
            width={isMobile ? "100%" : "852px"}
            lineHeight="40px"
          >
            {messages.fundingCalculatorLanding.tryFundingCalculator} 
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowIcon />}
            sx={{
              width: isMobile ? "100%" : "16rem",
              borderRadius: "12px",
              paddingX: "16px",
              paddingY: "12px",
              background: "#002F36",
              color: "#82D4F9",
              fontSize: "1.25rem",
              fontWeight: "700",
              textTransform: "none",
              ":hover": {
                background: "#002F36",
              },
            }}
            onClick={() => navigate(`${NAV_ROUTES.FUNDING_CALCULATOR_DETAILS}/${id}`)}
          >
            {labels.fundingCalculatorLanding.accessNow}
          </Button>
          <img
            src={fundingCalculatorTemplate}
            alt="funding-calculator-template"
          />
          <Typography color="#6B6B6B" fontSize="1rem" fontWeight="500">
            {messages.fundingCalculatorLanding.linkSendToEmail}
          </Typography>
        </Box>
      </Box>
    </Box >
  );
};

export default FundingCalculatorEmailConfirmation;
