import { FC, useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, useTheme, useMediaQuery, Typography, Button } from "@mui/material";
import {
  CURRENCY_LIMIT_100K,
  CURRENCY_LIMIT_100M,
  CURRENCY_LIMIT_10K,
  DEFAULT_MARKETING_SPENDS,
  DEFAULT_PAYBACK_PERIOD,
  DEFAULT_PAYBACK_PERIOD_OPTIONS,
  DEFAULT_PVX_FINANCING,
  DEFAULT_REVENUE_CHURN,
  DEFAULT_UA_INCREASE,
  PAYBACK_PERIOD_OPTIONS,
  PVX_FINANCING_OPTIONS,
} from "../../constants/constants";
import ErrorChart from '../../assets/images/error-chart.svg';
import Gear from '../../assets/icons/gear.svg';
import { RootState } from "../../stores/store";
import { CalculatorLayout } from "../../layouts";
import { FullPageLoaderWithText } from "../../components";
import FundingCalculatorSideNav from './components/FundingCalculatorSideNav';
import { CalculatorChartData } from "../../stores/FundingCalculator/types/fundingCalculator";
import FundingCalculatorChartBoard from "./components/FundingCalculatorChartBoard";
import FundingEmailPopover from "./components/FundingEmailPopover";
import useCalculationById from "../../hooks/api/useCalculationById";
import { UpdateFundingCalculationPayload } from "../../api/fundingCalculator";
import useUpdateFundingCalculation from "../../hooks/api/useUpdateFundingCalculation";
import useCalculationIdByEmail from "../../hooks/api/useCalculationIdByEmail";
import useUpdateFundingPlayground from "../../hooks/api/useUpdateFundingPlayground";
import useDebounce from "../../hooks/useDebounce";
import {
  getDefaultAvgRevenue,
  getDefaultPreviousMonthBalance,
  getDefaultOperatingExpenses
} from "../../utils/common";
import { genericTexts, labels } from "@utils/translations/en";
import CalculatorHeader from "./components/CalculatorHeader";
import CalculatorFooter from "./components/CalculatorFooter";

export type FundingCalculatorErrorState = {
  pvxFinancing: boolean;
  paybackPeriodInMonths: boolean;
  netRevenue: boolean;
  marketingSpends: boolean;
  latestMonthCashBalance: boolean;
  operatingExpenses: boolean;
  profitBeforeTaxes: boolean;
  revenueChurnPerMonth: boolean;
  uaIncreasePerMonth: boolean;
}

const defaultFundingCalculatorErrorState = {
  pvxFinancing: false,
  paybackPeriodInMonths: false,
  netRevenue: false,
  marketingSpends: false,
  latestMonthCashBalance: false,
  operatingExpenses: false,
  profitBeforeTaxes: false,
  revenueChurnPerMonth: false,
  uaIncreasePerMonth: false,
};

type FundingCalculatorPagePropType = {
  isPlayground?: boolean;
};

const FundingCalculatorPage: FC<FundingCalculatorPagePropType> = ({ isPlayground = false }) => {
  const { calculationId } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const isLargeDevice = useMediaQuery(theme.breakpoints.down("x1400"));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const scrollToCalculatorRef = useRef<HTMLDivElement | null>(null);
  const calculatorWrapperRef = useRef<HTMLDivElement | null>(null);
  const sidePanelWrapperRef = useRef<HTMLDivElement | null>(null);
  const canvasWrapperRef = useRef<HTMLDivElement | null>(null);
  const prevIsDynamicInterestCalculationRef = useRef(false);

  const fundingCalculatorData = useSelector(
    (state: RootState) => state?.FundingCalculatorData
  );

  const { getCalculationIdByEmail } = useCalculationIdByEmail();
  const { getCalculationsById } = useCalculationById();
  const { updateCalculation } = useUpdateFundingCalculation();
  const { updatePlaygroundCalculation } = useUpdateFundingPlayground();

  const [isDynamicInterestCalculation, setDynamicInterestCalculation] = useState<boolean>(false);
  const [monthlyExpenditureData, setMonthlyExpenditureData] = useState<CalculatorChartData>([]);
  const [errorState, setErrorState] = useState<FundingCalculatorErrorState>(defaultFundingCalculatorErrorState);
  const [calculatorDataError, setCalculatorDataError] = useState<boolean>(false);
  const [toggleLoader, setLoader] = useState<boolean>(true);
  const [toggleMobileSidePanel, setMobileSidePanel] = useState<boolean>(false);
  const [redrawCount, setReDrawcount] = useState<number>(0);
  const [stickyPanelState, setStickyPanelState] = useState<number>(-1);

  // FIXME: temp solun for page title
  document.title = 'PvX | Funding Calculator';

  // Hook to handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      if (!calculatorWrapperRef.current || !sidePanelWrapperRef.current || !canvasWrapperRef.current) return;

      const calculatorWrapperRect = calculatorWrapperRef.current.getBoundingClientRect();
      const sidePanelRect = sidePanelWrapperRef.current.getBoundingClientRect();

      // Check if the side panel has reached the top of the viewport
      if (calculatorWrapperRect.top < 10 && calculatorWrapperRect.bottom > sidePanelRect.height && stickyPanelState !== 0) {
        setStickyPanelState(0);
        sidePanelWrapperRef.current.style.position = 'fixed';
        sidePanelWrapperRef.current.style.top = '0';
        sidePanelWrapperRef.current.style.bottom = 'unset';
        sidePanelWrapperRef.current.style.marginTop = '1vh';
        canvasWrapperRef.current.style.marginLeft = '26rem';
      } else if (calculatorWrapperRect.top < 0 && calculatorWrapperRect.bottom <= sidePanelRect.height && stickyPanelState !== 1) {
        setStickyPanelState(1);
        sidePanelWrapperRef.current.style.position = 'absolute';
        sidePanelWrapperRef.current.style.top = 'unset';
        sidePanelWrapperRef.current.style.bottom = '0';
      } else if (calculatorWrapperRect.top > 0 && stickyPanelState !== -1) {
        setStickyPanelState(-1);
        sidePanelWrapperRef.current.style.position = 'unset';
        sidePanelWrapperRef.current.style.top = 'unset';
        sidePanelWrapperRef.current.style.bottom = 'unset';
        sidePanelWrapperRef.current.style.marginTop = '0';
        canvasWrapperRef.current.style.marginLeft = '0.5rem';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyPanelState]);

  // Hook to fetch data initially or on change of isDynamicInterestCalculation
  useEffect(() => {
    if (calculationId && !isPlayground) {
      const getData = async () => {
        const res = await getCalculationIdByEmail(calculationId);
        if (res?.data?.id) {
          const calculations = await getCalculationsById(res.data.id);

          const searchParams = new URLSearchParams(location.search);
          const utmParam = searchParams.get('utm');

          // Mixpanel Dispatch set idendity
          // Mixpanel.identify(calculations.email);

          // Mixpanel Dispatch set user property - email
          // Mixpanel.people.set({ $email: calculations.email });

          // Mixpanel Dispatch calculation_loaded event
          // Mixpanel.track('Load Funding Calculations', {
          //   id: calculations.id,
          //   email: calculations.email,
          //   paybackPeriod: Object.values(calculations.leadMagnetFinancials.monthlyFinancials)?.length,
          //   avgMonthlyMarketingSpend: calculations.leadMagnetFinancials.avgMarketingSpend,
          //   cumulativeRoas: Object.entries(calculations.leadMagnetRoasAndPayback.monthlyRoasAndPayback).reduce((acc, [key, value]) => ({ ...acc, [key]: { month: (value as any).month, cumulativeRoas: (value as any).cumulativeRoas } }), {}),
          //   investorFundingPercentage: calculations.leadMagnetFinancials.investorFundingPercentage,
          //   netRevenue: calculations.leadMagnetFinancials.avgRevenue,
          //   latestMonthCashBalance: calculations.leadMagnetFinancials.latestMonthCashBalance,
          //   operatingExpenses: calculations.leadMagnetFinancials.operatingExpenses,
          //   monthlySpendIncrease: calculations.leadMagnetFinancials.monthlySpendIncrease,
          //   summary: {
          //     cashCost: calculations.leadMagnetFinancials.cashCost,
          //     totalInvestorSpend: calculations.leadMagnetFinancials.marketingSpends.totalInvestorSpend,
          //     totalMarketingSpend: calculations.leadMagnetFinancials.marketingSpends.totalMarketingSpend,
          //   },
          //   utm: utmParam
          // });
        } else {
          setCalculatorDataError(true);
        }
      }
      getData();
    } else if (isPlayground) {
      const defaultData: UpdateFundingCalculationPayload = {
        id: '',
        paybackPeriod: DEFAULT_PAYBACK_PERIOD,
        avgMonthlyMarketingSpend: DEFAULT_MARKETING_SPENDS,
        cumulativeRoas: DEFAULT_PAYBACK_PERIOD_OPTIONS[DEFAULT_PAYBACK_PERIOD].map((i) => ({ month: i.month, cumulativeRoas: i.value / 100 })),
        investorFundingPercentage: DEFAULT_PVX_FINANCING,
        netRevenue: getDefaultAvgRevenue(DEFAULT_MARKETING_SPENDS),
        latestMonthCashBalance: getDefaultPreviousMonthBalance(DEFAULT_MARKETING_SPENDS),
        operatingExpenses: getDefaultOperatingExpenses(DEFAULT_MARKETING_SPENDS),
        monthlyRevenueChurn: DEFAULT_REVENUE_CHURN,
        monthlySpendIncrease: DEFAULT_UA_INCREASE,
        isDynamicInterestCalculation: isDynamicInterestCalculation
      };

      updatePlaygroundCalculation(defaultData);
    }
  }, [calculationId, isPlayground]);

  // Hook to error validate states
  useEffect(() => {
    handleRecalculateFundingError();

    if (fundingCalculatorData?.fundingCalculations?.id) {
      setLoader(false);
    }
  }, [fundingCalculatorData]);

  useEffect(() => {    
    if (prevIsDynamicInterestCalculationRef.current !== isDynamicInterestCalculation) {
      handleRecalculateFunding();
    }
  
    prevIsDynamicInterestCalculationRef.current = isDynamicInterestCalculation;
  }, [isDynamicInterestCalculation, prevIsDynamicInterestCalculationRef]);

  const handleScrollToCalculator = () => {
    if (scrollToCalculatorRef.current) {
      scrollToCalculatorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleDebouncedSetMonthlyExpenditure = useDebounce(setMonthlyExpenditureData, 800);

  const handleRecalculateFundingError = () => {
    if (fundingCalculatorData?.fundingCalculations?.id) {
      const errors: FundingCalculatorErrorState = { ...defaultFundingCalculatorErrorState };
      errors.paybackPeriodInMonths = !Boolean(PAYBACK_PERIOD_OPTIONS.some(
        (option) => option.value === fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths
      ));
      errors.pvxFinancing = !Boolean(PVX_FINANCING_OPTIONS.some(
        (option) => option.value === (fundingCalculatorData?.fundingCalculations?.variables?.pvxFinancing * 100)
      ));
      const { netRevenue, marketingSpends, latestMonthCashBalance, operatingExpenses } = fundingCalculatorData?.fundingCalculations?.variables?.latestMonthFinancials;
      errors.netRevenue = Boolean(netRevenue < CURRENCY_LIMIT_10K || netRevenue > CURRENCY_LIMIT_100M);
      errors.marketingSpends = Boolean(marketingSpends < CURRENCY_LIMIT_100K || marketingSpends > CURRENCY_LIMIT_100M);
      errors.latestMonthCashBalance = Boolean(latestMonthCashBalance < CURRENCY_LIMIT_10K || latestMonthCashBalance > CURRENCY_LIMIT_100M);
      errors.operatingExpenses = Boolean(operatingExpenses < CURRENCY_LIMIT_10K || operatingExpenses > CURRENCY_LIMIT_100M);

      setErrorState(errors);

      return errors;
    }
  };

  const handleRecalculateFunding = async () => {
    setLoader(true);
    setReDrawcount(redrawCount + 1);
    handleScrollToCalculator();
    if (!Boolean(Object.values(errorState).some((error) => error))) {
      const fundingVariables = fundingCalculatorData.fundingCalculations.variables;
      const payload: UpdateFundingCalculationPayload = {
        id: fundingCalculatorData.fundingCalculations.id || '',
        paybackPeriod: fundingVariables.paybackPeriodInMonths,
        avgMonthlyMarketingSpend: fundingVariables.latestMonthFinancials.marketingSpends,
        cumulativeRoas: monthlyExpenditureData.filter(item => item.month <= fundingVariables.paybackPeriodInMonths)
          .map((i) => ({ month: i.month, cumulativeRoas: i.value / 100 })),
        investorFundingPercentage: fundingVariables.pvxFinancing,
        netRevenue: fundingVariables.latestMonthFinancials.netRevenue,
        latestMonthCashBalance: fundingVariables.latestMonthFinancials.latestMonthCashBalance,
        operatingExpenses: fundingVariables.latestMonthFinancials.operatingExpenses,
        monthlyRevenueChurn: fundingVariables.revenueChurnPerMonth,
        monthlySpendIncrease: fundingVariables.uaIncreasePerMonth,
        isDynamicInterestCalculation: isDynamicInterestCalculation
      };

      if (!isPlayground && fundingCalculatorData.fundingCalculations.id) {
        // Mixpanel Dispatch set idendity
        // Mixpanel.identify(fundingCalculatorData.fundingCalculations.email);

        // Mixpanel Dispatch set user property - email
        // Mixpanel.people.set({ $email: fundingCalculatorData.fundingCalculations.email });

        // Mixpanel Dispatch recalculation_triggered event
        // Mixpanel.track('Recalculate Funding', {
        //   ...payload,
        //   email: fundingCalculatorData.fundingCalculations.email
        // });
        await updateCalculation(payload);
      } else {
        updatePlaygroundCalculation(payload)
      }


    }

    setLoader(false);
  };

  return (
    <CalculatorLayout>
      <Box
        sx={{
          height: "auto",
          background: theme.palette.funding_calculator.landing_page.background,
        }}
      >
        <CalculatorHeader view={2} onClickSimulateFacilitySize={handleScrollToCalculator} />
      </Box>
      <Box
        id="funding_calculator_wrapper"
        ref={scrollToCalculatorRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          px: isLargeDevice ? (isMediumDevice ? (isSmallDevice ? 0 : '2rem') : '4rem') : "6rem",
          gap: 3,
          background: theme.palette.funding_calculator.landing_page.background,
          flex: 1,
          mt: "2rem",
          mb: "5rem"
        }}
      >
        {calculatorDataError ? (
          <Box
            id="calculato_error_wrapper"
            sx={{
              width: '100%',
              maxWidth: '1280px',
              height: '100%',
              py: '1rem',
              px: isSmallDevice ? '1rem' : '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: isSmallDevice ? 'center' : 'left',
              background: theme.palette.funding_calculator.main_page.background
            }}
          >
            <img
              src={ErrorChart}
              alt={genericTexts.refreshChart}
              style={{
                width: '15rem',
                height: 'auto',
                marginBottom: 20
              }}
            />
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {genericTexts.oopsError}
            </Typography>
            <Typography style={{ textAlign: 'center' }}>
              {genericTexts.refreshChart}
            </Typography>
          </Box>
        ) : (
          <Box
            id="calculator_wrapper"
            ref={calculatorWrapperRef}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              mt: "1rem",
              flexDirection: isSmallDevice ? 'column' : 'row',
              background: theme.palette.funding_calculator.main_page.background,
              position: "relative"
            }}
          >
            {!isSmallDevice && (
              <div
                id="calculator_side_panel_wrapper"
                ref={sidePanelWrapperRef}
                style={{
                  width: '25rem',
                  height: '98vh',
                  boxSizing: 'border-box' as 'border-box',
                  marginRight: '0.5rem',
                  marginBottom: '1vh',
                  transition: 'position 0.3s ease',
                  zIndex: 10,
                }}
              >
                <FundingCalculatorSideNav
                  showLoader={toggleLoader}
                  handleRecalculateFunding={handleRecalculateFunding}
                  errorState={errorState}
                />
              </div>
            )}
            <Box
              id="calculator_summary_chart_canvas_wrapper"
              ref={canvasWrapperRef}
              sx={{
                width: isSmallDevice ? '100%' : 'calc(100% - 25rem)',
                height: isSmallDevice ? 'max-content' : '100%',
                overflowY: isSmallDevice ? 'unset' : 'auto',
                ml: '0.5rem',
                background: theme.palette.funding_calculator.main_page.background,
                boxSizing: 'border-box'
              }}
            >
              <FundingCalculatorChartBoard
                toggleLoader={toggleLoader}
                paybackPeriod={fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths || DEFAULT_PAYBACK_PERIOD}
                monthlyExpenditureData={monthlyExpenditureData}
                setMonthlyExpenditureData={handleDebouncedSetMonthlyExpenditure}
                isDynamicInterestCalculation={isDynamicInterestCalculation}
                handleChangeCalculationType={() => setDynamicInterestCalculation(!isDynamicInterestCalculation)}
                redrawCount={redrawCount}
              />
            </Box>
          </Box>
        )}
      </Box>
      <CalculatorFooter />
      {isSmallDevice && !toggleMobileSidePanel && (
        <Button
          id="funding_calculator_mobile_sidepanel_toggle"
          sx={{
            position: 'fixed',
            bottom: '4%',
            right: '4%',
            width: 'max-content',
            textTransform: "none",
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: '1rem',
            px: '2rem',
            fontSize: '1rem',
            borderRadius: '1rem',
            border: '0.5px solid #FFFFFF50',
            boxShadow: "0px 0px 5px 2px rgba(127, 188, 173, 0.5)",
            zIndex: 499,
          }}
          onClick={() => setMobileSidePanel(true)}
        >
          <img
            src={Gear}
            alt={genericTexts.refreshChart}
            style={{
              width: '2rem',
              height: '2rem',
            }}
          />
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: "700",
              fontFamily: "Manrope, sans-serif",
              marginLeft: '0.5rem'
            }}
          >
            {labels.fundingCalculatorPage.fundingSettings}
          </Typography>
        </Button>
      )}
      {isSmallDevice && toggleMobileSidePanel && (
        <Box
          id="calculator_mobile_side_panel_wrapper"
          sx={{
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            overflowY: 'unset',
            p: '0.25rem',
            boxSizing: 'border-box',
            zIndex: 9999
          }}
        >
          <FundingCalculatorSideNav
            showLoader={toggleLoader}
            handleRecalculateFunding={handleRecalculateFunding}
            errorState={errorState}
            setMobileSidePanel={setMobileSidePanel}
          />
          <Box sx={{ mb: "1rem" }} />
        </Box>
      )}
      {(toggleLoader && calculationId) && (
        <FullPageLoaderWithText text="Calculating funding eligibility..." />
      )}
      {(toggleLoader && !calculationId && !isPlayground) && (
        <FundingEmailPopover />
      )}
    </CalculatorLayout>
  );
}

export default FundingCalculatorPage;
