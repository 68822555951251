const titles = {
  PVX_PARTNERS: "PVX PARTNERS",
  USERS: "Users",
  USER_MANAGEMENT: "User Management",
  DASHBOARD: "Dashboard",
  SOMETHING_WENT_WRONG: "Oops, Something went wrong",
  PAGE_NOT_FOUND: "404: Page Not Found",
  INVITE_USER: "Invite User",
  USER_DETAILS: "User Details",
  ADD_FILTERS: "Add Filters",
  MODULE_DETAILS: "Module Details",
  FUNDING_CALCULATOR: "Funding Calculator",
};

const genericTexts = {
  goToHome: "< Go to home",
  pleaseWaitWhileWeFixIt: "Please wait while we fix it",
  redirecting: "Redirecting ...",
  authenticating: "Authenticating ...",
  loading: "Loading ...",
  noDashboardAccess:
    "Looks like you don’t have access to view any dashboards yet.",
  reachOutAdmin1: "Please reach out to your ",
  reachOutAdmin2: " to get the necessary permissions.",
  administrator: "administrator",
  pleaseWait: "Just a moment, we’re getting everything set up for you!",
  noDataAvailable: "No data available",
  Cancel: "Cancel",
  Active: "Active",
  Save: "Save",
  addNewModule: "Add New Module",
  netCumulativeROAS: "Net Cumulative ROAS ",
  oopsError: "Oops! Looks like we hit a snag...",
  refreshChart: "Please refresh the graph and try again.",
  pvxFunding: "PvX Funding",
  percent: " (%)",
  inMonths: " (in months)",
  dollar: ' ($)'
};

const messages = {
  fundingCalculatorLanding: {
    fundingCalculatorTitleText:
      "Looking for capital to power up your marketing budgets?",
    fundingCalculatorSubTitleText:
      "Some of the largest companies in mobile gaming use leverage to fund their user acquisition. Discover how much funding you could qualify for in just a few clicks.",
    paybackPeriodInMonths: "Payback Period (in months)",
    getFundingUpTo: "Get funding up to",
    requestAnEstimate: "Request a cost estimate for your facility",
    privacyPolicyAgreement:
      "By clicking on \"Get Estimate\", I agree to be contacted by the PvX team and the occasional marketing email. Don't worry, we won't spam and you can unsubscribe at any time. For more information, see our Privacy Policy",
    disclaimerMessage:
      "The funding amount presented is indicative and subject to change based on PvX’s underwriting process.",
    disclaimer: `DISCLAIMER `,
    tryFundingCalculator:
      "Try our Funding Calculator to simulate how UA funding affects your business metrics",
    checkInboxInFewMinutes:
      "Thank you for requesting a cost estimate. Please check your inbox in a few minutes to see the results.",
    getFundingUpto: "Get funding up to ",
    whatsNext: "What's Next",
    linkSendToEmail:
      "A link to the Funding Calculator has also been sent to your email address.",
    netCumulativeROAStooltip:
      "is the percentage of total marketing spend of a cohort recovered by net revenues in a given month (post deduction of platform fees and other sales-related direct costs)",
    netCumulativeROAS: "Cumulative Net Cumulative ROAS",
  },
  fundingCalculatorPage: {
    pvxFunding: "PvX Funding",
    calculator: "Calculator",
    simulateFacility: "Simulate your facility size and indicative cash cost",
    explorePotentialOutcomes:
      "Explore potential business outcomes with UA funding and make informed decisions for your business’s success.",
    pvxFundingTooltip:
      "PvX Funding (%) represents the percentage contribution of your UA budget that will be covered by PvX during the financing period.",
    monthlyUAIncreaseTooltip:
      "Monthly UA Increase (%) represents the assumed percentage month-on-month growth in UA budget.",
    paybackPeriodTooltip1:
      "Payback Period represents the number of months your cohorts typically recover all marketing expenses through the net revenues it generates.",
    paybackPeriodTooltip2:
      "In other words the number of months it takes to reach net ROAS 100%.",
    getFundingTerms: "Get funding terms within days",
    bookConsultation: "Book a consultation with a PvX representative",
    indicativeFundingAmountTooltip:
      "Estimated PvX funding for the first 12 months. Note that the funding amount is indicative and may change based on PvX's underwriting process.",
    cashCostTooltip:
      " is the total cost of funding expressed as a percentage of the total amount borrowed by the company.",
    latestMonthFinancialsTooltip1: 'Net Revenue should be post deduction of platform fees and other sales-related direct costs.',
    latestMonthFinancialsTooltip2: 'Average spends towards user acquisition on a monthly basis',
    latestMonthFinancialsTooltip3: 'Average monthly costs required to support the game’s development.',
    latestMonthFinancialsTooltip4: 'Amount of cash or cash-equivalents available on your balance sheet at the end of the most recent month.',
    increaseInProfitsTooltip: (val: number) => (`Expected increase in profits before taxes by the end of first ${val} months of funding`),
    increaseInCashBalanceTooltip: (val: number) => (`Expected increase in company’s cash balance by the end of first ${val} months of funding`)
  },
};

const labels = {
  fundingCalculatorLanding: {
    getEstimate: "Get Estimate",
    enterNetCumulativeRoas: "Enter Net. Cumulative ROAS (%)",
    enterGameData: "Enter Game Details",
    estimateFundingPotential: "Estimate Funding Potential",
    enterPlaystoreAppstore: "Enter Play Store / App Store link",
    enterLaunchMonth: "Launch Month (MM/YY)",
    enterAverageMarketingSpend: "Avg. Monthly Marketing Spend ($)",
    accessNow: "Access Now",
  },
  fundingCalculatorPage: {
    fundingSettings: "CALCULATOR INPUT",
    monthlyUAIncrease: "Monthly UA Increase",
    paybackPeriod: "Payback Period",
    recalculate: "Recalculate",
    monthlyMarketingSpends: "Monthly Marketing Spends",
    netRevenue: "Net Revenue",
    cumulativeProfits: 'Cumulative',
    cumulativeProfitBeforeTaxes: "Cumulative Profit Before Taxes",
    profit: "Profit Before Taxes",
    cumulative: "Cumulative",
    monthly: "Monthly",
    endingCashBalance: "Ending Cash Balance",
    principalAndInterestRepayment:
      "Illustrative Principal and Interest Repayment Schedule for the First Cohort",
    getConnected: "Get Connected",
    bookNow: "Book Now",
    monthlyUserAcquisitionSpend: "New User Acquisition Spend",
    indicativeFundingAmount: "Indicative Funding Amount",
    cashCost: "Cash Cost",
    marketingSpends: 'Marketing Spends',
    operatingExpenses: 'Operating Expenses',
    lastMonthCashBalance: 'Latest Month Cash Balance',
    lastMonthFinancials: 'Latest Month Financials',
    increaseInProfits: 'Increase in Profits',
    increaseInCashBalance: 'Increase in your Cash Balance',
    dynamicCapInterest: 'Dynamic Return Cap',
    simpleInterest: 'Simple Interest',
  },
};

export { titles, messages, labels, genericTexts };
