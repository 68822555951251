import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { labels, messages } from "@utils/translations/en";
import CalculatorSummaryArc from "./CalculatorSummaryArc";
import FundingCalculatorSummary from "./FundingCalculatorSummary";
import CalculatorOutputIcon from '../../../assets/icons/v2/calculator-output-icon.svg'
import { ReactComponent as SwitchIcon } from '../../../assets/icons/v2/switch_icon.svg';
import infoIconQuestion from '../../../assets/images/v2/info_icon_question.svg';
import useDebounce from "@hooks/useDebounce";
import { CustomTooltip } from "@components";

interface FundingCalculatorSummaryWrapperProps {
  isChartLoading: boolean;
  toggleLoader: boolean;
  cashBalanceIncrease: string;
  redrawCount: number;
  handleChangeCalculationType: () => void;
  isDynamicInterestCalculation: boolean;
}

const FundingCalculatorSummaryWrapper: React.FC<
  FundingCalculatorSummaryWrapperProps
> = ({ isChartLoading, toggleLoader, cashBalanceIncrease, redrawCount, handleChangeCalculationType, isDynamicInterestCalculation }) => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleDebouncedChangeCalculationType = useDebounce(handleChangeCalculationType, 800);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "max-content",
            display: 'flex',
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "flex-start" : 'center',
            pt: '0.8rem',
            pb: '1.5rem',
            fontSize: '1rem',
            background: theme.palette.common.white,
          }}
        >
          <Box
            sx={{
              width: "max-content",
              height: "max-content",
              display: 'flex',
              justifyContent: "flex-start",
              alignItems: 'center',
              fontSize: '1rem',
              background: theme.palette.common.white,
            }}
          >
            <img
              src={CalculatorOutputIcon}
              alt={"Calculator Output"}
              style={{
                width: '2rem',
                height: '2rem',
                marginRight: "1rem"
              }}
            />
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 800,
                color: "#162936",
                fontSize: "1.5rem",
              }}
            >
              {isChartLoading ? "Calculating..." : "Calculator Output"}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "max-content",
              height: "max-content",
              display: 'flex',
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: 'center',
              fontSize: '1rem',
              background: theme.palette.common.white,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "13rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                mt: isMobile ? "1rem" : 0,
                mx: isMobile ? "0.5rem" : "1rem",
                background: "theme.palette.common.white",
                borderRadius: "100px",
                py: "0.5rem",
                px: "0.5rem",
                color: theme.palette.custom_menu.font,
                borderColor: theme.palette.custom_background,
                letterSpacing: "-3%",
                fontSize: "1rem",
                lineHeight: "15px",
                fontWeight: 600,
                textTransform: "none",
                fontFamily: "Manrope, sans-serif",
                "&:hover": {
                  backgroundColor: "#F6F8F9",
                },
              }}
              onClick={() => handleDebouncedChangeCalculationType()}
            >
              <SwitchIcon style={{ height: "1.5rem", marginRight: "0.5rem" }} />
              <Typography
                sx={{
                  textTransform: "none",
                  fontSize: '1rem',
                  fontWeight: 500,
                  color: theme.palette.common.black,
                }}
                fontFamily="Manrope, sans-serif"
              >
                {`${isDynamicInterestCalculation ? labels.fundingCalculatorPage.dynamicCapInterest : labels.fundingCalculatorPage.simpleInterest}`}
              </Typography>
            </Button>
            <CustomTooltip
              content={
                <>
                  <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem", }}>
                    <b>{`${isDynamicInterestCalculation ? labels.fundingCalculatorPage.dynamicCapInterest.toUpperCase() : labels.fundingCalculatorPage.simpleInterest.toUpperCase()}`}</b>
                  </div>
                  <br />
                  <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                    {/* <b>{`${isDynamicInterestCalculation ? labels.fundingCalculatorPage.dynamicCapInterest : labels.fundingCalculatorPage.simpleInterest}`}</b> is....
                    <br /> */}
                  </div>
                </>
              }>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: isSmallMobile ? "1rem" : 0,
                  width: isSmallMobile ? "1.5rem" : "2rem",
                  height: isSmallMobile ? "1.5rem" : "2rem",
                  border: `1px solid #989CA270`,
                  borderRadius: "5rem", background: theme.palette.common.white
                }}
              >
                <img src={infoIconQuestion} alt="Info Icon" style={{ width: isSmallMobile ? "0.8rem" : "1rem", height: 'auto' }} />
              </div>
            </CustomTooltip>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <CalculatorSummaryArc redrawCount={redrawCount} />
          <FundingCalculatorSummary showLoader={toggleLoader} cashBalanceIncrease={cashBalanceIncrease} />
        </Box>
      </Box>
      <Typography
        sx={{
          mt: "1.5rem",
          fontSize: "0.65rem",
          fontWeight: 800,
          fontFamily: "Manrope, sans-serif",
          color: "#898C8D",
        }}
      >
        <b>{messages.fundingCalculatorLanding.disclaimer}</b>
        {messages.fundingCalculatorLanding.disclaimerMessage}
      </Typography>
    </>
  );
};

export default FundingCalculatorSummaryWrapper;
